<!--<div class='Settle'></div>-->
<!--    门店分润报表-->
<template>
    <div class="big">
        <p :style="{ marginLeft: '20px', color: 'red', fontSize: '12px', fontWeight: '800' }">
            说明：报表显示已经生成分润的数据，以结算时间倒序显示
        </p>
        <div v-if="show" :key="1" class="Settle" style="background-color: #f2f2f2; margin-top: 8px">
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: left"
                    v-model="form"
                    size="small"
                    label-width="80px"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="接单机构">
                                <el-select placeholder="全部" v-model="form.receiveShopCode" filterable>
                                    <el-option
                                        v-for="dept in depts"
                                        :label="dept.name"
                                        :value="dept.code"
                                        :key="dept.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="分润机构">
                                <el-select placeholder="门店名称" v-model="form.ownerShopCode" filterable>
                                    <el-option
                                        v-for="dept in profitDistributionMechanism"
                                        :label="dept.name"
                                        :value="dept.code"
                                        :key="dept.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="订单编号">
                                <el-input placeholder="订单编号" v-model="form.orderNumber" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="退款编号">
                                <el-input placeholder="退款编号" v-model="form.refundNumber" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="推荐人">
                                <el-input
                                    placeholder="姓名/手机号"
                                    v-model="form.parentDistributionEmployeeNameOrMobile"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="订单状态">
                                <el-select placeholder="全部" v-model="form.orderStatus">
                                    <el-option
                                        v-for="e in meta.statusOfTheOrder"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="退款类型">
                                <el-select placeholder="全部" v-model="form.refundType">
                                    <el-option
                                        v-for="e in meta.refundStatus"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="打款状态">
                                <el-select placeholder="全部" v-model="form.remitMoneyStatus">
                                    <el-option
                                        v-for="e in meta.paymentStatus"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="订单类型">
                                <el-select placeholder="全部" v-model="form.orderType">
                                    <el-option
                                        v-for="e in meta.orderType"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-select
                                    placeholder="推荐人/下单员工"
                                    v-model="form.selectColumnType"
                                    @change="change(form.selectColumnType)"
                                >
                                    <el-option
                                        v-for="e in meta.selectColumnType"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-select v-model="form.provinceCode">
                                    <!--                                    <el-option value="" label="请选择" />-->
                                    <el-option
                                        :value="g.code"
                                        :key="g.code"
                                        :label="g.name"
                                        v-for="g in meta.provinces"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-select
                                    placeholder="推荐人/下单员工"
                                    v-model="form.selectColumnType"
                                    @change="change(form.selectColumnType)"
                                >
                                    <el-option
                                        v-for="e in meta.selectColumnTypeCity"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-select v-model="form.cityCode">
                                    <el-option value="" label="请选择" />
                                    <el-option
                                        :value="g.code"
                                        :key="g.code"
                                        :label="g.name"
                                        v-for="g in meta.cityList"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="预结算时间-开始" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="form.preCreateStartTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="预结算时间-结束" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="form.preCreateEndTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="下单时间-开始" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="form.orderCreateStartTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="下单时间-结束" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="form.orderCreateEndTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: right">
                <span style="float: left">
                    <el-button size="big" @click="tabone" :style="{ background: show ? '#eeeeee' : '' }"
                        >报表明细</el-button
                    >
                    <el-button size="big" @click="tabtwo" :style="{ background: show ? '' : '#eeeeee' }"
                        >总计</el-button
                    >
                </span>
                <el-button
                    type="primary"
                    @click="searchHandleQuery"
                    size="small"
                    v-if="hasPrivilege('menu.mall.manage.deptShareProfit.open')"
                >
                    查询
                </el-button>
                <!--              报表明细导出-->
                <el-button
                    type="primary"
                    @click="detailedhandleExport"
                    size="small"
                    v-if="hasPrivilege('menu.mall.manage.deptShareProfit.export')"
                >
                    导出
                </el-button>
                <el-button type="primary" @click="clearDetails" size="small"> 清空</el-button>
                <el-button type="primary" @click="showCheckTableShowColumnDialog" size="small"> 表头</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px">
                <el-table
                    id="settleTable"
                    ref="settleTable"
                    border
                    stripe
                    style="width: 100%"
                    :data="tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column
                        label="接单机构"
                        width="96"
                        prop="receiveShopName"
                        key="receiveShopName"
                        fixed="left"
                        v-if="showColumn('receiveShopName')"
                    />
                    <el-table-column
                        label="编号"
                        width="300"
                        prop="orderNumber"
                        key="orderNumber"
                        align="right"
                        v-if="showColumn('orderNumber')"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.orderNumber">订单编号：{{ scope.row.orderNumber }}</p>
                            <p v-if="scope.row.refundSns">退款编号：{{ scope.row.refundSns }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="实付金额(元)"
                        width="160"
                        prop="orderActualAmount"
                        key="orderActualAmount"
                        align="right"
                        sortable
                        v-if="showColumn('orderActualAmount')"
                    >
                        <template slot="header">
                            <el-tooltip effect="light" placement="top">
                                <div slot="content">
                                    订单实付的金额（如订单中包含礼品，则礼品的金额会计入实付金额计算分润）
                                </div>
                                <span>实付金额 <i class="el-icon-info"></i></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <span :style="{ textAlign: 'center' }">
                                <p
                                    :style="{ color: 'red' }"
                                    v-if="scope.row.orderActualAmount || scope.row.orderActualAmount == 0"
                                >
                                    {{ scope.row.orderActualAmount }}元
                                </p>
                                <p
                                    :style="{ color: 'red' }"
                                    v-if="scope.row.freightAmount || scope.row.freightAmount == 0"
                                >
                                    （含运费:{{ scope.row.freightAmount }}）
                                </p>
                                <p>共{{ scope.row.productNums }}<span v-if="scope.row.productNums">件</span></p>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="分润金额"
                        width="160"
                        prop="orderTotalShareProfitAmount"
                        key="orderTotalShareProfitAmount"
                        v-if="showColumn('orderTotalShareProfitAmount')"
                        sortable
                    >
                        <template slot="header">
                            <el-tooltip
                                effect="light"
                                content="分润金额：门店发货分润金额=订单实付金额  - 退款金额 - 已结算商分金额 - 税额  - 成本价（未退款）- 买家寄回运费；八号地发货分润金额=订单实付金额  - 退款金额  - 已结算商分金额 - 税额 - 成本价（未退款） - 仓库寄出运费（7元）- 买家寄回运费"
                                placement="top"
                            >
                                <span>分润金额 <i class="el-icon-info"></i></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.orderTotalShareProfitAmount
                                }}<span v-if="scope.row.orderTotalShareProfitAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="退款金额(元)"
                        width="160"
                        prop="refundActualAmount"
                        v-if="showColumn('refundActualAmount')"
                        sortable
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.refundActualAmount || scope.row.refundActualAmount == 0">
                                {{ scope.row.refundActualAmount }}元
                            </p>
                            <p v-if="scope.row.refundGoodsNum || scope.row.refundGoodsNum == 0">
                                {{ scope.row.refundGoodsNum }}件
                            </p>
                        </template>
                    </el-table-column>
                    >
                    <el-table-column
                        align="right"
                        label="税额(元)"
                        width="160"
                        prop="taxAmount"
                        key="taxAmount"
                        v-if="showColumn('taxAmount')"
                        sortable
                    >
                        <template slot="header">
                            <el-tooltip
                                effect="light"
                                content="税额=∑（（商品实付金额-商品成本价*单个SKU购买数量）/（1+税率）*税率）-（（退款金额-商品成本价*退款SKU数量）/（1+税率）*税率）+用户支付运费金额/（1+13%）*13%"
                                placement="top"
                            >
                                <span>税额(元) <i class="el-icon-info"></i></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <span>{{ scope.row.taxAmount }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="推广商品金额(元)"
                        width="160"
                        prop="canDistributionAmount"
                        key="canDistributionAmount"
                        v-if="showColumn('canDistributionAmount')"
                        sortable
                    >
                        <template slot="header">
                            <el-tooltip
                                effect="light"
                                content="推广商品金额：订单中可参与分销的商品金额总和（含礼品金额）"
                                placement="top"
                            >
                                <span>推广商品金额 <i class="el-icon-info"></i></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.canDistributionAmount
                                }}<span v-if="scope.row.canDistributionAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="商分金额"
                        width="160"
                        prop="orderIncomeAmount"
                        key="orderIncomeAmount"
                        v-if="showColumn('orderIncomeAmount')"
                        sortable
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.orderIncomeAmount || scope.row.orderIncomeAmount == 0"
                                >{{ scope.row.orderIncomeAmount }}元</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="差异商分金额"
                        width="160"
                        prop="minusIncomeAmount"
                        key="minusIncomeAmount"
                        v-if="showColumn('minusIncomeAmount')"
                        sortable
                    >
                        <template slot="header">
                            <el-tooltip
                                effect="light"
                                content="差异商分金额：商分金额四舍五入给到分销员，产生的差异金额"
                                placement="top"
                            >
                                <span>差异商分金额 <i class="el-icon-info"></i></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.minusIncomeAmount
                                }}<span v-if="scope.row.minusIncomeAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="批发价(元)"
                        width="160"
                        prop="prodCostAmount"
                        key="prodCostAmount"
                        sortable
                        v-if="hasPrivilege('biz.distributionData.prod.cost.show')"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.prodCostAmount }}<span v-if="scope.row.prodCostAmount">元</span></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="仓库寄出运费(元)"
                        width="160"
                        prop="orderDeliveryFreightCostAmount"
                        key="orderDeliveryFreightCostAmount"
                        v-if="showColumn('orderDeliveryFreightCostAmount')"
                        sortable
                    >
                        <template slot="header">
                            <el-tooltip
                                effect="light"
                                content="仓库寄出运费:仓库发货给快递公司的费用，目前统一按照7元"
                                placement="top"
                            >
                                <span>仓库寄出运费(元) <i class="el-icon-info"></i></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <span>{{ scope.row.orderDeliveryFreightCostAmount }}</span>
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column-->
                    <!--                        align="right"-->
                    <!--                        label="礼品价格（元）"-->
                    <!--                        width="160"-->
                    <!--                        prop="giftAmount"-->
                    <!--                        key="giftAmount"-->
                    <!--                        v-if="showColumn('giftAmount')"-->
                    <!--                        sortable-->
                    <!--                    >-->
                    <!--                    </el-table-column>-->
                    <!--                    <el-table-column-->
                    <!--                        align="right"-->
                    <!--                        label="结算时间"-->
                    <!--                        width="160"-->
                    <!--                        prop="bizTime"-->
                    <!--                        key="bizTime"-->
                    <!--                        v-if="showColumn('bizTime')"-->
                    <!--                        sortable-->
                    <!--                    >-->
                    <!--                    </el-table-column>-->
                    <el-table-column
                        align="right"
                        label="预结算时间"
                        width="160"
                        prop="preCreateTime"
                        key="preCreateTime"
                        v-if="showColumn('preCreateTime')"
                        sortable
                    >
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="确认收货时间"
                        width="160"
                        prop="finallyTime"
                        key="finallyTime"
                        v-if="showColumn('finallyTime')"
                        sortable
                    />
                    <el-table-column
                        align="right"
                        label="下单时间"
                        width="160"
                        prop="orderCreateTime"
                        key="orderCreateTime"
                        v-if="showColumn('orderCreateTime')"
                        sortable
                    />
                    <el-table-column
                        align="right"
                        label="状态"
                        width="160"
                        prop="orderStatus"
                        key="orderStatus"
                        v-if="showColumn('orderStatus')"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.orderStatus == 5">交易成功</span>
                            <span v-if="scope.row.orderStatus == 6">交易失败</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="打款状态"
                        width="160"
                        prop="remitMoneyStatus"
                        key="remitMoneyStatus"
                        v-if="showColumn('remitMoneyStatus')"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.remitMoneyStatus == 0">未打款</span>
                            <span v-if="scope.row.remitMoneyStatus == 1">已打款</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="退款类型"
                        width="160"
                        prop="refundType"
                        key="refundType"
                        v-if="showColumn('refundType')"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.refundType == 1">整单退款</span>
                            <span v-if="scope.row.refundType == 2">单个物品退款</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="推荐人"
                        width="160"
                        prop="parentDistributionEmployeeName"
                        key="parentDistributionEmployeeName"
                        v-if="showColumn('parentDistributionEmployeeName')"
                    >
                        <template slot-scope="scope">
                            <p>{{ scope.row.parentDistributionEmployeeName }}</p>
                            <p>{{ scope.row.parentDistributionEmployeeMobile }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="推荐人所在门店"
                        width="160"
                        prop="parentDistributionDeptName"
                        key="parentDistributionDeptName"
                        v-if="showColumn('parentDistributionDeptName')"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.parentDistributionProvinceName }}
                            </p>
                            <p>
                                {{ scope.row.parentDistributionCityName }}
                            </p>
                            <p>{{ scope.row.parentDistributionShopName }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="下单人是否为员工"
                        width="160"
                        prop="isEmployee"
                        key="isEmployee"
                        v-if="showColumn('isEmployee')"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.isEmployee == true">是</span>
                            <span v-if="!scope.row.isEmployee">否</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="员工姓名"
                        width="160"
                        prop="currentDistributionEmployeeName"
                        key="currentDistributionEmployeeName"
                        v-if="showColumn('currentDistributionEmployeeName')"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.currentDistributionEmployeeName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="员工所在门店"
                        width="160"
                        prop="currentDistributionShopName"
                        key="currentDistributionShopName"
                        v-if="showColumn('currentDistributionShopName')"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.currentDistributionProvinceName }}
                            </p>
                            <p>
                                {{ scope.row.currentDistributionCityName }}
                            </p>
                            <p>{{ scope.row.currentDistributionShopName }}</p>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :page-sizes="page.pageSizes"
                    :page-size="form.limit"
                    :layout="page.PageStyle"
                    :total="page.total"
                    :current-page="form.page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
            <check-table-show-column-dialog
                ref="checkTableShowColumnDialog"
                @checkTableShowColumn="setShowTableColumns"
            />
            <ef-review-biz ref="review" baseUrl="/report/monthSettle" @completed="handleQuery" />
        </div>

        <div v-if="!show" :key="2" class="Settle" style="background-color: #f2f2f2; margin-top: 8px">
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: left"
                    v-model="form"
                    size="small"
                    label-width="80px"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="分润机构">
                                <el-select placeholder="本店名称" v-model="totalform.deptCode" filterable>
                                    <el-option
                                        v-for="dept in totalProfitDistributionstores"
                                        :label="dept.name"
                                        :value="dept.code"
                                        :key="dept.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="打款状态">
                                <el-select placeholder="全部" v-model="totalform.remitMoneyStatus">
                                    <el-option
                                        v-for="e in meta.paymentStatus"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="创建时间-开始" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="totalform.startTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建时间-结束" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="totalform.endTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="预结算时间-开始" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="totalform.preCreateStartTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="预结算时间-结束" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="totalform.preCreateEndTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="下单时间-开始" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="totalform.orderCreateStartTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="下单时间-结束" label-width="125px">
                                <el-date-picker
                                    type="date"
                                    v-model="totalform.orderCreateEndTimeStr"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                    style="width: 220px"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: right">
                <span style="float: left">
                    <el-button size="big" @click="tabone" :style="{ background: show ? '#eeeeee' : '' }"
                        >报表明细</el-button
                    >
                    <el-button size="big" @click="tabtwo" :style="{ background: show ? '' : '#eeeeee' }"
                        >总计</el-button
                    >
                </span>
                <el-button
                    type="primary"
                    @click="searchTotalHandleQuery"
                    size="small"
                    v-if="hasPrivilege('menu.mall.manage.deptShareProfit.open')"
                >
                    查询
                </el-button>
                <!--              汇总分润报表导出-->
                <el-button
                    type="primary"
                    @click="SummaryhandleExport"
                    size="small"
                    v-if="hasPrivilege('menu.mall.manage.deptShareProfit.export')"
                >
                    导出
                </el-button>
                <el-button type="primary" @click="totalEmpty" size="small"> 清空</el-button>
                <el-button type="primary" @click="dialogFormVisible = true" size="small"> 表头</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px">
                <el-table
                    id="settleTable"
                    ref="settleTable"
                    border
                    stripe
                    style="width: 100%"
                    :data="totalTableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column
                        label="分润机构"
                        width="200"
                        prop="ownerShopName"
                        key="ownerShopName"
                        v-if="checkedCities.indexOf('分润机构') !== -1"
                    >
                    </el-table-column>
                    <el-table-column
                        label="分润总金额"
                        width="200"
                        prop="totalShareProfitAmount"
                        key="totalShareProfitAmount"
                        sortable
                        v-if="checkedCities.indexOf('分润总金额') !== -1"
                    >
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.totalShareProfitAmount
                                }}<span v-if="scope.row.totalShareProfitAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="订单支付总金额"
                        width="200"
                        prop="totalActualAmount"
                        key="totalActualAmount"
                        sortable
                        v-if="checkedCities.indexOf('订单支付总金额') !== -1"
                    >
                        <template slot="header">
                            <el-tooltip
                                effect="light"
                                content="订单实付的金额（如订单中包含礼品，则礼品的金额会计入实付金额计算分润）"
                                placement="top"
                            >
                                <span>订单支付总金额 <i class="el-icon-info"></i></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.totalActualAmount
                                }}<span v-if="scope.row.totalActualAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="退款总金额"
                        width="200"
                        prop="totalRefundActualAmount"
                        key="totalRefundActualAmount"
                        sortable
                        v-if="checkedCities.indexOf('退款总金额') !== -1"
                    >
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.totalRefundActualAmount
                                }}<span v-if="scope.row.totalRefundActualAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="税额总计"
                        width="200"
                        prop="totalTaxAmount"
                        key="totalTaxAmount"
                        align="right"
                        sortable
                        v-if="checkedCities.indexOf('税额总计') !== -1"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.totalTaxAmount }}<span v-if="scope.row.totalTaxAmount">元</span></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="商分金额总计"
                        width="200"
                        prop="totalIncomeAmount"
                        key="totalIncomeAmount"
                        sortable
                        v-if="checkedCities.indexOf('商分金额总计') !== -1"
                    >
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.totalIncomeAmount
                                }}<span v-if="scope.row.totalIncomeAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="差异金额总计"
                        width="200"
                        prop="totalIncomeDiffAmount"
                        key="totalIncomeDiffAmount"
                        sortable
                        v-if="checkedCities.indexOf('差异金额总计') !== -1"
                    >
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.totalIncomeDiffAmount
                                }}<span v-if="scope.row.totalIncomeDiffAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column-->
                    <!--                        align="right"-->
                    <!--                        label="礼品价格总计"-->
                    <!--                        width="200"-->
                    <!--                        prop="totalGiftAmount"-->
                    <!--                        key="totalGiftAmount"-->
                    <!--                        sortable-->
                    <!--                        v-if="checkedCities.indexOf('礼品价格总计') !== -1"-->
                    <!--                    >-->
                    <!--                        <template slot-scope="scope">-->
                    <!--                            <span>{{ scope.row.totalGiftAmount }}<span v-if="scope.row.totalGiftAmount">元</span></span>-->
                    <!--                        </template>-->
                    <!--                    </el-table-column>-->
                    <el-table-column
                        align="right"
                        label="批发金额总计"
                        width="200"
                        prop="totalCostAmount"
                        key="totalCostAmount"
                        sortable
                        v-if="hasPrivilege('biz.distributionData.prod.cost.show')"
                    >
                        <template slot="header">
                            <el-tooltip effect="light" content="已计入订单支付总金额" placement="top">
                                <span>礼品价格总计 <i class="el-icon-info"></i></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <span>{{ scope.row.totalCostAmount }}<span v-if="scope.row.totalCostAmount">元</span></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="仓库寄出运费总计"
                        width="200"
                        prop="totalOrderDeliveryFreightCostAmount"
                        key="totalOrderDeliveryFreightCostAmount"
                        sortable
                        v-if="checkedCities.indexOf('仓库寄出运费总计') !== -1"
                    >
                        <template slot-scope="scope">
                            <span
                                >{{ scope.row.totalOrderDeliveryFreightCostAmount
                                }}<span v-if="scope.row.totalOrderDeliveryFreightCostAmount">元</span></span
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :page-sizes="pagetwo.pageSizes"
                    :page-size="totalform.limit"
                    :layout="pagetwo.PageStyle"
                    :total="pagetwo.total"
                    :current-page="totalform.page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
            <check-table-show-column-dialog
                ref="checkTableShowColumnDialog"
                @checkTableShowColumn="setShowTableColumns"
            />
            <ef-review-biz ref="review" baseUrl="/report/monthSettle" @completed="handleQuery" />
            <el-dialog title="选择表格要展示的列" :visible.sync="dialogFormVisible">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" border
                    >全选
                </el-checkbox>
                <div style="margin: 15px 0"></div>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
                </el-checkbox-group>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="totalHeader">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfReviewBiz from 'components/EfReviewBiz';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import Provinces from 'js/Provinces';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import { get } from 'request/http';

const cityOptions = [
    '分润机构',
    '分润总金额',
    '订单支付总金额',
    '退款总金额',
    '税额总计',
    '商分金额总计',
    '差异金额总计',
    '仓库寄出运费总计',
    '礼品价格总计',
];
export default {
    name: 'DeptGroupShareProfit',
    // eslint-disable-next-line vue/no-unused-components
    components: { EfEndDate, EfStartDate, EfDeptTypeAndDeptSelect, EfReviewBiz, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            // 报表明细
            form: {
                receiveShopCode: null, //接单机构
                ownerShopCode: null, //分润机构
                orderNumber: '', //订单编号
                refundNumber: '', //退款编号
                parentDistributionEmployeeNameOrMobile: '', //上级推荐人的名字/手机号
                orderStatus: null, //订单状态
                refundType: null, //退款单类型（1:整单退款,2:单个物品退款）
                remitMoneyStatus: null, //打款状态 0未打款 1已打款
                provinceCode: '', //省
                cityCode: '', //市
                selectColumnType: 0, //推荐人 下单员工
                startTimeStr: '', //开始时间
                endTimeStr: '', //结束时间
                preCreateStartTimeStr: null, //预结算时间开始
                preCreateEndTimeStr: null, //预结算时间结束
                orderCreateStartTimeStr: null,
                orderCreateEndTimeStr: null,
                page: 1,
                limit: 20,
                orderType: null, //订单类型 0普通订单 1团购订单 2秒杀订单,3积分订单 4礼品订单 5 直播订单
            },
            // 总计表单
            totalform: {
                deptCode: null, //总计分润机构
                remitMoneyStatus: null, //总计打款状态
                startTimeStr: null, //开始时间
                endTimeStr: null, //结束时间
                preCreateStartTimeStr: null, //预结算时间开始
                preCreateEndTimeStr: null, //预结算时间结束
                orderCreateStartTimeStr: null,
                orderCreateEndTimeStr: null,
                page: 1,
                limit: 20,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            pagetwo: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            totalTableData: [],
            url: {
                page: '/report/monthSettle/page',
                delete: '/report/monthSettle/delete',
            },
            current: {
                typeIdx: 3,
            },
            meta: {
                cityList: [],
                provinces: Provinces,
                types: [],
                mechanism: [],
                //订单状态
                statusOfTheOrder: [
                    { code: null, label: '全部' },
                    { code: 5, label: '交易成功' },
                    { code: 6, label: '交易失败' },
                ],
                selectColumnType: [
                    { code: 0, label: '推荐人所在省' },
                    { code: 1, label: '下单员工所在省' },
                ],
                selectColumnTypeCity: [
                    { code: 0, label: '推荐人所在市' },
                    { code: 1, label: '下单员工所在市' },
                ],
                //退款状态
                refundStatus: [
                    { code: null, label: '全部' },
                    { code: 1, label: '整单退款' },
                    { code: 2, label: '单个物品退款' },
                ],
                //打款状态
                paymentStatus: [
                    { code: null, label: '全部' },
                    { code: 0, label: '未打款' },
                    { code: 1, label: '已打款' },
                ],
                //订单类型
                orderType: [
                    { code: null, label: '全部' },
                    { code: 0, label: '普通订单' },
                    { code: 1, label: '团购订单' },
                    { code: 2, label: '秒杀订单' },
                    { code: 3, label: '积分订单' },
                    { code: 4, label: '礼品订单' },
                    { code: 5, label: '直播订单' },
                ],
                showReview: false,
            },
            tableRef: 'settleTable',
            inCostDiffSummaryMoreType: false,
            inCostDiffSummaryMoreFrom: [],
            show: true,
            depts: [],
            profitDistributionMechanism: [], //分润机构下拉
            totalProfitDistributionstores: [], //总计分润门店
            index: 0,
            recommenderOrOrderEmployee: '省',
            recommenderOrOrdercity: '市',
            //全选框
            dialogFormVisible: false,
            checkAll: false,
            checkedCities: [
                '分润机构',
                '分润总金额',
                '订单支付总金额',
                '退款总金额',
                '税额总计',
                '商分金额总计',
                '差异金额总计',
                '仓库寄出运费总计',
                '礼品价格总计',
            ],
            cities: cityOptions,
            isIndeterminate: true,
        };
    },
    beforeCreate() {},
    mounted() {
        /*
接单门店，收款门店
*/
        this.$efApi.deptApi.managedAllDepts().then((rst) => {
            this.depts = rst;
            this.profitDistributionMechanism = [...rst];
            this.totalProfitDistributionstores = [...rst];
            var json = { name: '全部', code: null };
            this.depts.unshift(json);
            //替换电商总部
            this.depts.forEach((dept) => {
                if (dept.code === 'TDSDPT15604211379688') {
                    dept.name = '电商总部';
                }
            });
            this.profitDistributionMechanism.forEach((dept) => {
                if (dept.code === 'TDSDPT15604211379688') {
                    dept.name = '电商总部';
                }
            });
            this.totalProfitDistributionstores.forEach((dept) => {
                if (dept.code === 'TDSDPT15604211379688') {
                    dept.name = '电商总部';
                }
            });
            this.totalform.deptCode = rst[1].code;
            this.form.ownerShopCode = this.profitDistributionMechanism[0].code;
            if (JSON.stringify(this.meta.provinces).indexOf(JSON.stringify(json)) === -1) {
                this.meta.provinces.unshift(json);
            }
            this.handleQuery();
        });
    },
    watch: {
        // 省市下拉二级联动
        'form.provinceCode': async function (newProvinceCode) {
            this.form.cityCode = '';
            // this.form.districtCode = '';
            this.meta.districtList = [];
            let cityList = [];
            if (newProvinceCode) {
                //根据省份查询市列表
                cityList = await get('/system/area/list', { parentCode: newProvinceCode });
            }
            this.meta.cityList = cityList || [];
            const json = { name: '全部', code: null };
            if (JSON.stringify(this.meta.cityList).indexOf(JSON.stringify(json)) === -1) {
                this.meta.cityList.unshift(json);
            }
        },
    },
    methods: {
        //总计表头
        totalHeader() {
            this.dialogFormVisible = false;
        },
        //全选反选
        handleCheckAllChange(val) {
            this.checkedCities = val ? cityOptions : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            const checkedCount = value.length;
            this.checkAll = checkedCount === this.cities.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        },
        change(value) {
            if (value == null) {
                this.recommenderOrOrderEmployee = '省';
                this.recommenderOrOrdercity = '市';
            } else if (value == 0) {
                this.recommenderOrOrderEmployee = '推荐人所在省';
                this.recommenderOrOrdercity = '推荐人所在市';
            } else if (value == 1) {
                this.recommenderOrOrderEmployee = '下单员工所在省';
                this.recommenderOrOrdercity = '下单员工所在市';
            }
        },
        cityChange(val) {
            this.provinceTypeLabelcity = this.meta.reviewEnumsCity[val].label;
        },
        tabone() {
            this.show = true;
            this.handleQuery();
        },
        tabtwo() {
            this.show = false;
            this.totalhandleQuery();
        },
        clearDetails() {
            this.form.orderType = null; //订单类型
            this.form.receiveShopCode = null; //接单门店
            this.form.ownerShopCode = this.profitDistributionMechanism[0].code; //分润店铺
            this.form.orderNumber = ''; //订单编号
            this.form.refundNumber = ''; //退款编号
            this.form.parentDistributionEmployeeNameOrMobile = ''; //上级推荐人的名字/手机号
            this.form.orderStatus = null; //订单状态
            this.form.refundType = null; //退款单类型（1:整单退款,2:单个物品退款）
            this.form.remitMoneyStatus = null; //打款状态 0未打款 1已打款
            this.form.provinceCode = this.meta.provinces[0].code;
            this.form.cityCode = ''; //市
            this.form.selectColumnType = this.meta.selectColumnType[0].code; //推荐人 下单员工
            this.form.preCreateStartTimeStr = null; //预结算时间开始
            this.form.preCreateEndTimeStr = null; //预结算时间结束
            this.form.orderCreateStartTimeStr = null;
            this.form.orderCreateEndTimeStr = null;
            this.form.startTimeStr = null;
            this.form.endTimeStr = null;
        },
        totalEmpty() {
            this.totalform.deptCode = this.totalProfitDistributionstores[0].code; //总计分润门店
            this.totalform.remitMoneyStatus = null; //总计打款状态
            this.totalform.preCreateStartTimeStr = null; //预结算时间开始
            this.totalform.preCreateEndTimeStr = null; //预结算时间结束
            this.totalform.orderCreateStartTimeStr = null;
            this.totalform.orderCreateEndTimeStr = null;
            this.totalform.startTimeStr = null;
            this.totalform.endTimeStr = null;
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        //门店明细查询
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, '/shareProfit/shareProfitDeptDetail', _params);
        },
        searchTotalHandleQuery() {
            this.totalform.page = 1;
            this.totalhandleQuery();
        },
        //总计查询
        totalhandleQuery() {
            const _this = this;
            const _params = { params: _this.totalform };
            _this.$http.get('/shareProfit/shareProfitDeptSum', _params).then((rst) => {
                //前端判断是否为空数组里套空对象
                if (!rst.data.data[0].ownerShopName) {
                    this.pagetwo.total = rst.data.count;
                    this.totalTableData = [];
                } else {
                    this.totalTableData = rst.data.data;
                    this.pagetwo.total = rst.data.count;
                }
            });
        },
        //报表明细导出
        detailedhandleExport() {
            const _this = this;
            UrlUtils.Export(_this, '市场分润明细报表', '/shareProfit/shareProfitDeptDetailExport', _this.form);
        },
        //汇总导出
        SummaryhandleExport() {
            const _this = this;
            UrlUtils.Export(_this, '市场分润汇总报表', '/shareProfit/shareProfitSumDeptExport', _this.totalform);
        },
        handleSelectType() {
            const _this = this;
            _this.form.deptType = _this.meta.types[_this.current.typeIdx].type;
        },
        mFour: Util.mFour,
        mCount: Util.mCount,
    },
};
</script>

<style scoped>
.Settle .el-form-item {
    margin-bottom: 0;
}
</style>
<style>
.greenClass :hover {
    cursor: pointer !important;
}
</style>
